import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col} from 'react-bootstrap';
import teraLogo from '../../assets/images/terra-logo.svg';
import mfileLogo from '../../assets/images/mfile-icon.png';
import iconAssets from '../../assets/images/icon-assets.svg';
import iconRewards from '../../assets/images/icon-rewards.svg';
import iconStakers from '../../assets/images/icon-stakers.svg';
import iconCurve from '../../assets/images/img-curve.png';
import './StatsNetwork.css';
function StatsNetwork(props) {
	return (
		<React.Fragment>
			<section id="stats" className="stats text-center content-section text-white bg-purple position-relative">
				<div className="curve-image">
					<img src={iconCurve} alt="" />
				</div>
				<Container>
					<Row>
						<Col md="4">
							<div className="stats-block d-flex flex-column">
								<div className="icon-block d-flex justify-content-center mb-4">
									<div className="icon-holder d-flex justify-content-center align-items-center">
										<img className="img-fluid" src={iconAssets} alt="" />
									</div>
								</div>
								<div className="d-flex flex-column">
									<span className="stat-text mb-2">Total staking assets</span>
									<span className="stat-value">$1,453,883,888</span>
								</div>
							</div>
						</Col>
						<Col md="4">
							<div className="stats-block d-flex flex-column">
								<div className="icon-block d-flex justify-content-center mb-4">
									<div className="icon-holder">
										<img className="img-fluid" src={iconRewards} alt="" />
									</div>
								</div>
								<div className="d-flex flex-column">
									<span className="stat-text mb-2">Total rewards paid</span>
									<span className="stat-value">$18,953,955</span>
								</div>
							</div>
						</Col>
						<Col md="4">
							<div className="stats-block d-flex flex-column">
								<div className="icon-block d-flex justify-content-center mb-4">
									<div className="icon-holder">
										<img className="img-fluid" src={iconStakers} alt="" />
									</div>
								</div>
								<div className="d-flex flex-column">
									<span className="stat-text mb-2">Stakers</span>
									<span className="stat-value">15,156</span>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section id="networks" className="networks text-center content-section">
				<Container>
					<div className="heading-section text-center">
						<h2 className="mb-2">Choose a network</h2>
						<p>mFile lets you stake tokens from many networks. Choose a network below to get started.</p>
					</div>
					<Row className="justify-content-center">
						<Col xl="5" lg="6" sm="12">
							<div className="network-block mb-4 mb-lg-0">
								<div className="icon-block mb-4 mb-md-5 d-flex justify-content-center">
									<div className="icon-holder">
										<img className="img-fluid" src={mfileLogo} alt="" />
									</div>
								</div>
								<div className="network-detail mb-3 mb-md-5">
									<h2 className="mb-3">mFile</h2>
									<p>Stake any amount of ETH and earn daily staking rewards. Put your staked mFile to work acrosss DeFi to compound your yield.</p>
								</div>
								<div className="netwrok-stats d-flex flex-column flex-sm-row jsutify-content-between mb-4 mb-sm-4 mb-md-5">
									<div className="network-stat d-flex flex-column">
										<strong className="network-value text-success">5.8%</strong>
										<span className="network-text">APR</span>
									</div>
									<div className="network-stat d-flex flex-column">
										<strong className="network-value">$1,046,172,634</strong>
										<span className="network-text">Staked</span>
									</div>
								</div>
								<div className="btns-holder d-flex justify-content-center flex-column flex-sm-row">
									<Link className="mfileBtn btn-primary mx-3 mb-3 mb-sm-0" to="/stake">Stake Now</Link>
									<Link className="mfileBtn btn-secondary mx-3" to="/">Read More</Link>
								</div>
							</div>
						</Col>
						<Col xl="5" lg="6" sm="12">
							<div className="network-block mb-4 mb-lg-0">
								<div className="icon-block mb-4 mb-md-5 d-flex justify-content-center">
									<div className="icon-holder">
										<img className="img-fluid" src={teraLogo} alt="" />
									</div>
								</div>
								<div className="network-detail mb-3 mb-md-5">
									<h2 className="mb-3">Terra</h2>
									<p>Stake LUNA to earn daily bLUNA staking rewards. Maintain full control of your staked tokens and use them across Terra DeFi applications.</p>
								</div>
								<div className="netwrok-stats d-flex flex-column flex-sm-row jsutify-content-between mb-4 mb-sm-4 mb-md-5">
									<div className="network-stat d-flex flex-column">
										<strong className="network-value">3.8%</strong>
										<span className="network-text">APR</span>
									</div>
									<div className="network-stat d-flex flex-column">
										<strong className="network-value">$393,259,183</strong>
										<span className="network-text">Staked</span>
									</div>
								</div>
								<div className="btns-holder d-flex justify-content-center flex-column flex-sm-row">
									<Link className="mfileBtn btn-primary mx-3 mb-3 mb-sm-0" to="/stake">Stake Now</Link>
									<Link className="mfileBtn btn-secondary mx-3" to="/">Read More</Link>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</React.Fragment>
	);
}

export default StatsNetwork;