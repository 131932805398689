import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import Landing from './components/Landing/Landing';
import FAQ from './components/FAQ/FAQ';
import Stake from './components/Stake/Stake';
import Wrap from './components/Wrap/Wrap';
export default () => (

<Router>
	<Switch>
		<Route exact path="/" component={Landing} />
		<Route exact path="/faqs" name="FAQ" component={FAQ} />
		<Route exact path="/stake" name="FAQ" component={Stake} />
		<Route exact path="/wrap" name="FAQ" component={Wrap} />
	</Switch>
</Router>
);