import React from 'react';
import { Link } from "react-router-dom";
import siteLogo from '../../assets/images/logo.png';
import { Container, Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faRedditAlien } from '@fortawesome/free-brands-svg-icons'
import './Footer.css';
function Footer(props) {
	return (
		<React.Fragment>
			<footer id="footer">
				<Container>
					<Row>
						<Col lg="3" md="6" sm="12">
							<div className="footer-col text-white">
								<strong className="footer-logo d-inline-block align-top mb-3">
									<Link to="/">
										<img className="img-fluid" src={siteLogo} alt="" />
									</Link>
								</strong>
								<div className="mb-4">
									<p>mFile lets you stake tokens from many networks. Choose a network below to get started.</p>
								</div>
								<ul className="social-links list-unstyled">
									<li className="d-inline-block align-top"><a href="#"><FontAwesomeIcon icon={faTelegramPlane} /></a></li>
									<li className="d-inline-block align-top"><a href="#"><FontAwesomeIcon icon={faDiscord} /></a></li>
									<li className="d-inline-block align-top"><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
									<li className="d-inline-block align-top"><a href="#"><FontAwesomeIcon icon={faGithub} /></a></li>
									<li className="d-inline-block align-top"><a href="#"><FontAwesomeIcon icon={faRedditAlien} /></a></li>
								</ul>
							</div>
						</Col>
						<Col lg="3" md="6" sm="6">
							<div className="footer-col text-white">
								<h3 className="mb-4">Product</h3>
								<ul className="footer-nav list-unstyled">
									<li><Link to="/">Stake with mFile</Link></li>
									<li><Link to="/">mFile Ecosystem</Link></li>
									<li><Link to="/">Nansen Analytics</Link></li>
									<li><Link to="/">Dune Analytics</Link></li>
								</ul>
							</div>
						</Col>
						<Col lg="3" md="6" sm="6">
							<div className="footer-col text-white">
								<h3 className="mb-4">Governance</h3>
								<ul className="footer-nav list-unstyled">
									<li><Link to="/">Forum</Link></li>
									<li><Link to="/">User Guide</Link></li>
									<li><Link to="/">mFile DAO</Link></li>
									<li><Link to="/">Terms of Use</Link></li>
									<li><Link to="/">Privacy Notice</Link></li>
									<li><Link to="/">mFile Bug Bounty</Link></li>
								</ul>
							</div>
						</Col>
						<Col lg="3" md="6" sm="6">
							<div className="footer-col text-white">
								<h3 className="mb-4">Governance</h3>
								<ul className="footer-nav list-unstyled">
									<li><Link to="/">Forum</Link></li>
									<li><Link to="/">User Guide</Link></li>
									<li><Link to="/">mFile DAO</Link></li>
									<li><Link to="/">Terms of Use</Link></li>
									<li><Link to="/">Privacy Notice</Link></li>
									<li><Link to="/">mFile Bug Bounty</Link></li>
								</ul>
							</div>
						</Col>
					</Row>
				</Container>
				<Container fluid>
					<div className="copyright-block text-center">
						<p>Copyright &copy; 2021. All Rights Reserved by mFile.</p>
					</div>
				</Container>
			</footer>
		</React.Fragment>
	);
}

export default Footer;