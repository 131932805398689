import React from 'react';
import { Link, useHistory } from "react-router-dom";
import siteLogo from '../../assets/images/logo.png';
import { Container, Row, Col, Navbar, Nav, NavDropdown} from 'react-bootstrap';
import './Header.css';
function Header(props) {
	console.log("props")
		console.log(props)
		const history = useHistory();
		console.log(history);
		const pathname = history.location.pathname;

	return (
		
		<React.Fragment>
			<header id="header">
				<Container fluid className="text-right">
					<div className="header-bottom">
						<Row className="align-items-center">
							<Col md="2" className="text-left">
								<strong className="logo d-inline-block align-top">
									<Link to="/" className="d-inline-block align-top">
										<img className="img-fluid" src={siteLogo} alt="" />
									</Link>
								</strong>
							</Col>
							<Col md="10" className="pos-stat">
								<Navbar expand="lg" className="pos-stat">
									<Navbar.Toggle aria-controls="basic-navbar-nav" />
									<Navbar.Collapse id="basic-navbar-nav">
										<ul className="navbar-nav ml-auto">
											<li className={`nav-item ${pathname==='/' ? 'active' : ''}`}><Link className="nav-link" to="/">Home </Link></li>
											<li className={`nav-item ${pathname==='/stake' ? 'active' : ''}`}><Link className="nav-link" to="/stake">Stake</Link></li>
											<li className={`nav-item ${pathname==='/wrap' ? 'active' : ''}`}><Link className="nav-link" to="/wrap">Wrap</Link></li>
											<li className={`nav-item ${pathname==='/faqs' ? 'active' : ''}`}><Link className="nav-link" to="/faqs">FAQs</Link></li>
										</ul>
									</Navbar.Collapse>
								</Navbar>
							</Col>
						</Row>
					</div>
				</Container>
			</header>
		</React.Fragment>
	);
}

export default Header;