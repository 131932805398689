import React from 'react';
import { Link } from "react-router-dom";
import bannerImg from '../../assets/images/globe.svg';
import Globe from 'globe.gl';
import { Container, Row, Col} from 'react-bootstrap';
import './MainBanner.css';
function MainBanner(props) {
	return (
		<React.Fragment>
			<section id="main-banner" className="main-banner">
				<Container fluid>
					<Row className="align-items-center">
						<Col lg="7" md="6">
							<div className="mb-4 mb-md-0 pl-4">
								<div className="banner-text text-white mb-5">
									<h1>Liquidity for staked assets</h1>
									<p>Simplified and secure staking for digital assets.</p>
								</div>
								<Link to="/" className="mfileBtn btn-white-outline d-inline-block align-top">Stake Now</Link>
							</div>
						</Col>
						<Col lg="5" md="6">
							<div id="banner-img" className="banner-img text-center">
								<img className="img-fluid" src={bannerImg} alt="" />
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</React.Fragment>
	);
}

export default MainBanner;