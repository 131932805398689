import React from 'react';
import { Link } from "react-router-dom";
import bannerImg from '../../assets/images/img-filecoin.png';
import { Container, Row, Col} from 'react-bootstrap';
import './PageBanner.css';
function PageBanner(props) {
	return (
		<React.Fragment>
			<section id="page-banner" className="page-banner">
				<Container>
					<Row className="justify-content-center align-items-center text-center">
						<Col md="10">
							<div className="banner-text text-white">
								<h1>{props.title}</h1>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</React.Fragment>
	);
}

export default PageBanner;