import React from 'react';
import Header from '../Header/Header';
import MainBanner from '../MainBanner/MainBanner';
import StatsNetwork from '../StatsNetwork/StatsNetwork';
import NoteBlock from '../NoteBlock/NoteBlock';
import HowWork from '../HowWork/HowWork';
import Footer from '../Footer/Footer';
import './Landing.css';
function Landing(props) {
	return (
		<React.Fragment>
			<Header />
			<MainBanner />
			<StatsNetwork />
			<NoteBlock />
			<HowWork />
			<Footer />
		</React.Fragment>
	);
}

export default Landing;