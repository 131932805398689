import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col} from 'react-bootstrap';
import stakeImage from '../../assets/images/shield.svg';
import './NoteBlock.css';
function NoteBlock(props) {
	return (
		<React.Fragment>
			<section id="note-block" className="note-block content-section bg-grey">
				<Container>
					<Row className="justify-content-center text-center">
						<Col md="10">
							<div className="icon-holder d-inline-block align-top mb-3 mb-md-4">
								<img src={stakeImage} alt="" />
							</div>
							<h2 className="mb-2">Your assets are safe now!</h2>
							<p>mFile works with Unslashed Finance to cover stakers against slashing risks.</p>
						</Col>
					</Row>
				</Container>
			</section>
		</React.Fragment>
	);
}

export default NoteBlock;