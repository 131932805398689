import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col} from 'react-bootstrap';
import iconStake from '../../assets/images/stake.svg';
import iconMint from '../../assets/images/mint.svg';
import iconDefi from '../../assets/images/defi.svg';
import iconCommunity from '../../assets/images/community.svg';

import './HowWork.css';
function HowWork(props) {
	return (
		<React.Fragment>
			<section id="how-work" className="how-work content-section">
				<Container>
					<div className="heading-section text-center">
						<h2 className="mb-2">How mFile works</h2>
						<p>mFile builds state of the art liquid staking protocols to grow the staking economy.</p>
					</div>
					<Row className="justify-content-center text-center">
						<Col md="6" sm="12">
							<div className="how-work-block">
								<div className="icon-block mb-3 mb-md-4">
									<div className="icon-holder d-inline-block align-top">
										<img src={iconStake} alt="" />
									</div>
								</div>
								<h2 className="mb-3">Stake</h2>
								<p>mFile lets users stake their assets for daily staking rewards. User can stake any amount of tokens - no minimum.</p>
							</div>
						</Col>
						<Col md="6" sm="12">
							<div className="how-work-block">
								<div className="icon-block mb-3 mb-md-4">
									<div className="icon-holder d-inline-block align-top">
										<img src={iconMint} alt="" />
									</div>
								</div>
								<h2 className="mb-3">Mint</h2>
								<p>When staking mFile you mint staked tokens which are pegged 1:1 to your initial stake. Your staked tokens can be used across the DeFi ecosystem to compound your yield.</p>
							</div>
						</Col>
						<Col md="6" sm="12">
							<div className="how-work-block">
								<div className="icon-block mb-3 mb-md-4">
									<div className="icon-holder d-inline-block align-top">
										<img src={iconDefi} alt="" />
									</div>
								</div>
								<h2 className="mb-3">Defi</h2>
								<p>mFile lets you use your staked assets to gain yield on top of yield. Use your tokens (which earn daily staking rewards) as collateral, for lending, yield farming and more.</p>
							</div>
						</Col>
						<Col md="6" sm="12">
							<div className="how-work-block">
								<div className="icon-block mb-3 mb-md-4">
									<div className="icon-holder d-inline-block align-top">
										<img src={iconCommunity} alt="" />
									</div>
								</div>
								<h2 className="mb-3">Community</h2>
								<p>mFile DAO is a community that builds liquid staking services and governs the direction of mFile. The number of DAO participants is growing daily with contributors working together to build the future of mFile.</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</React.Fragment>
	);
}

export default HowWork;