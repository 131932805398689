import React , { useState } from 'react';
import {Link} from 'react-router-dom';
import Header from '../Header/Header';
import PageBanner from '../PageBanner/PageBanner';
import Footer from '../Footer/Footer';
import { Container, Row, Col, Tabs, Tab, Form} from 'react-bootstrap';
import Select from 'react-select';
import './Wrap.css';
const options = [
	{ value: 'mfile', label: 'mFile' },
	{ value: 'ethereum', label: 'Ethereum' },
];



const Wrap = (props) => {

	const [selectedOption, setSelectedOption] = useState({ value: 'mfile', label: 'mFile' });
	const handleChange = (e) => {
		setSelectedOption(e)
	}


	return (
		<React.Fragment>
			<Header />
			<PageBanner title="Wrap"/>
			<div className="wrap-page content-section">
				<Container>
					<div className="heading-section text-center">
						<h2 className="mb-2">Wrap &amp; Unwrap</h2>
						<p>Stable-balance stETH wrapper for DeFi.</p>
					</div>
					<Row className="justify-content-center">
						<Col md="10">
							<Tabs defaultActiveKey="wrap" id="uncontrolled-tab-example" className="mb-3">
								<Tab eventKey="wrap" title="Wrap">
									<div className="d-flex mb-3 flex-column flex-lg-row">
										<Form className="wrap-unwrap-form flex-fill mr-0 mr-lg-2 d-flex flex-column flex-sm-row position-relative mb-2 mb-lg-0">
											<div className="select-holder">
												<Select
													value={selectedOption}
													onChange={(e) => handleChange(e)}
													options={options}
													classNamePrefix="react-select"
												/>
											</div>
											<div className="input-holder flex-fill">
												<input type="number" placeholder="ETH Amount" />
												<button type="button" className="max-btn text-uppercase">MAX</button>
											</div>
										</Form>
										<div>
											<Link className="mfileBtn btn-primary d-inline-block align-top w-100 text-center" to="/">Connect Wallet</Link>
										</div>
									</div>
									<div className="wrap-stats mb-3">
										<ul className="list-unstyled services-list">
											<li className="d-flex mb-3">
												<span className="service-name flex-fill mr-2">Unlock fee</span>
												<span className="service-charges">$2.16</span>
											</li>
											<li className="d-flex mb-3">
												<span className="service-name flex-fill mr-2">Gas fee</span>
												<span className="service-charges">$4.32</span>
											</li>
											<li className="d-flex mb-3">
												<span className="service-name flex-fill mr-2">Exchange rate</span>
												<span className="service-charges">1 stETH = 0.9729 wstETH</span>
											</li>
											<li className="d-flex mb-3">
												<span className="service-name flex-fill mr-2">Allowance</span>
												<span className="service-charges">0.0</span>
											</li>
											<li className="d-flex mb-0">
												<span className="service-name flex-fill mr-2">You will receive</span>
												<span className="service-charges">0 wstETH</span>
											</li>
										</ul>
									</div>
								</Tab>
								<Tab eventKey="unwrap" title="Unwrap">
									<div className="d-flex mb-3 flex-column flex-lg-row">
										<Form className="wrap-unwrap-form flex-fill mr-0 mr-lg-2 d-flex flex-column flex-sm-row position-relative mb-2 mb-lg-0">
											<div className="input-holder flex-fill">
												<input type="number" placeholder="ETH Amount" />
												<button type="button" className="max-btn text-uppercase">MAX</button>
											</div>
										</Form>
										<div>
											<Link className="mfileBtn btn-primary d-inline-block align-top w-100 text-center" to="/">Connect Wallet</Link>
										</div>
									</div>
									<div className="wrap-stats mb-3">
										<ul className="list-unstyled services-list">
											<li className="d-flex mb-3">
												<span className="service-name flex-fill mr-2">Gas fee</span>
												<span className="service-charges">$4.32</span>
											</li>
											<li className="d-flex mb-0">
												<span className="service-name flex-fill mr-2">Exchange rate</span>
												<span className="service-charges">1 stETH = 0.9729 wstETH</span>
											</li>
										</ul>
									</div>
								</Tab>
							</Tabs>
							<div>
								<p>Got some burning questions in mind, visit <Link to="/faqs">FAQs</Link> section.</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</React.Fragment>
	);
}

export default Wrap;