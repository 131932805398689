import React from 'react';
import Header from '../Header/Header';
import PageBanner from '../PageBanner/PageBanner';
import Footer from '../Footer/Footer';
import { Container, Row, Col, Accordion, Card, Button} from 'react-bootstrap';
import './FAQ.css';
function FAQ(props) {
	return (
		<React.Fragment>
			<Header />
				<PageBanner title="FAQs"/>
				<section id="faqas" className="faqs content-section">
					<Container>
						<Row>
							<Col sm="12">
								<Accordion defaultActiveKey="0">
									<Card className="mb-3">
										<Card.Header className="p-0">
											<Accordion.Toggle as={Button} variant="link" eventKey="0" className="w-100 d-block text-left">
												What is mFile?
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="0">
											<div>
												<div className="spacer">&nbsp;</div>
												<Card.Body>
													<p>mFile is a liquid staking solution for ETH 2.0 backed by industry-leading staking providers. mFile lets users stake their ETH - without locking assets or maintaining infrastructure - whilst participating in on-chain activities, e.g. lending.</p>
													<p>Our goal is to solve the problems associated with initial ETH 2.0 staking - illiquidity, immovability and accessibility - making staked ETH liquid and allowing for participation with any amount of ETH to improve security of the Ethereum network.</p>
												</Card.Body>
											</div>
										</Accordion.Collapse>
									</Card>

									<Card className="mb-3">
										<Card.Header className="p-0">
											<Accordion.Toggle as={Button} variant="link" eventKey="1" className="w-100 d-block text-left">
												How does mFile work?
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="1">
											<div>
												<div className="spacer">&nbsp;</div>
												<Card.Body>
													<p>When staking with mFile, users receive stETH tokens on a 1:1 basis representing their staked ETH. stETH balances can be used like regular ETH to earn yields and lending rewards, and are updated on a daily basis to reflect your ETH staking rewards. Note that there are no lock-ups or minimum deposits when staking with mFile.</p>
													<p>When using mFile, users receive secure staking rewards in real-time, allowing for participation in the securing of Ethereum without the associated risks and downside potential.</p>
												</Card.Body>
											</div>
										</Accordion.Collapse>
									</Card>

									<Card className="mb-3">
										<Card.Header className="p-0">
											<Accordion.Toggle as={Button} variant="link" eventKey="2" className="w-100 d-block text-left">
												What is liquid staking?
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="2">
											<div>
												<div className="spacer">&nbsp;</div>
												<Card.Body>
													<p>Liquid staking protocols allow users to earn staking rewards without locking assets or maintaining staking infrastructure. Users can deposit tokens and receive tradable liquid tokens in return. The DAO-controlled smart contract stakes these tokens using elected staking providers. As users funds are controlled by the DAO, staking providers never have direct access to the users' assets.</p>
												</Card.Body>
											</div>
										</Accordion.Collapse>
									</Card>

									<Card className="mb-3">
										<Card.Header className="p-0">
											<Accordion.Toggle as={Button} variant="link" eventKey="3" className="w-100 d-block text-left">
												What is stETH?
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="3">
											<div>
												<div className="spacer">&nbsp;</div>
												<Card.Body>
													<p>stETH is a token that represents staked ether in mFile, combining the value of initial deposit + staking rewards. stETH tokens are minted upon deposit and burned when redeemed. stETH token balances are pegged 1:1 to the ethers that are staked by mFile. stETH token’s balances are updated when the oracle reports change in total stake every day.</p>
													<p>stETH tokens can be used as one would use ether, allowing you to earn ETH 2.0 staking rewards whilst benefiting from e.g. yields across decentralised finance products.</p>
												</Card.Body>
											</div>
										</Accordion.Collapse>
									</Card>

									<Card className="mb-3">
										<Card.Header className="p-0">
											<Accordion.Toggle as={Button} variant="link" eventKey="4" className="w-100 d-block text-left">
												What is LDO?
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="4">
											<div>
												<div className="spacer">&nbsp;</div>
												<Card.Body>
													<p>LDO is an Ethereum token granting governance rights in the mFile DAO. The mFile DAO governs a set of liquid staking protocols, decides on key parameters (e.g., fees) and executes protocol upgrades to ensure efficiency and stability. By holding the LDO token, one is granted voting rights within the mFile DAO. The more LDO locked in a user’s voting contract, the greater the decision-making power the voter gets.</p>
												</Card.Body>
											</div>
										</Accordion.Collapse>
									</Card>

									<Card className="mb-3">
										<Card.Header className="p-0">
											<Accordion.Toggle as={Button} variant="link" eventKey="5" className="w-100 d-block text-left">
												How is mFile secure?
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="5">
											<div>
												<div className="spacer">&nbsp;</div>
												<Card.Body>
													<p>mFile is a secure liquid staking solution for a number of reasons:</p>
													<ul className="list-unstyled">
														<li>Open-sourcing &amp; continuous review of all code.</li>
														<li>Committee of elected, best-in-class validators to minimise staking risk.</li>
														<li>Use of non-custodial staking service to eliminate counterparty risk.</li>
														<li>Use of DAO for governance decisions &amp; to manage risk factors.</li>
														<li>Usually when staking ETH you choose only one validator. In the case of mFile you stake across many validators, minimising your staking risk.</li>
													</ul>
												</Card.Body>
											</div>
										</Accordion.Collapse>
									</Card>

									<Card className="mb-3">
										<Card.Header className="p-0">
											<Accordion.Toggle as={Button} variant="link" eventKey="6" className="w-100 d-block text-left">
												What is the difference between self staking and liquid staking?
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="6">
											<div>
												<div className="spacer">&nbsp;</div>
												<Card.Body>
													<p>Ethereum is soon to be the biggest staking economy in the space. However, staking on ETH 2.0 requires expert knowledge and complex and costly infrastructure. There are several reasons why - the main being the fact that slashing and offline penalties can get very severe if the staking is managed improperly. In addition to this, self-staking brings with it a minimum deposit of 32 ETH and a token lock-up which could last years.</p>
													<p>Through the use of a liquid self-staking service such as mFile, users can eliminate these inconveniences and benefit from secure, non-custodial staking backed by industry leaders.</p>
												</Card.Body>
											</div>
										</Accordion.Collapse>
									</Card>

									<Card className="mb-3">
										<Card.Header className="p-0">
											<Accordion.Toggle as={Button} variant="link" eventKey="7" className="w-100 d-block text-left">
												What are the risks of staking with mFile?
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="7">
											<div>
												<div className="spacer">&nbsp;</div>
												<Card.Body>
													<p>There exist a number of potential risks when staking ETH using liquid staking protocols.</p>
													<ul>
														<li>
															<strong className="d-block">Smart contract security</strong>
															<p>There is an inherent risk that mFile could contain a smart contract vulnerability or bug. The mFile code is open-sourced, audited and covered by an extensive bug bounty program to minimise this risk.</p>
														</li>
														<li>
															<strong className="d-block">ETH 2.0 - Technical risk</strong>
															<p>mFile is built atop experimental technology under active development, and there is no guarantee that ETH 2.0 has been developed error-free. Any vulnerabilities inherent to ETH 2.0 brings with it slashing risk, as well as stETH fluctuation risk.</p>
														</li>
														<li>
															<strong className="d-block">ETH 2.0 - Adoption risk</strong>
															<p>The value of stETH is built around the staking rewards associated with the Ethereum beacon chain. If ETH 2.0 fails to reach required levels of adoption we could experience significant fluctuations in the value of ETH and stETH.</p>
														</li>
														<li>
															<strong className="d-block">DAO key management risk</strong>
															<p>Ether staked via the mFile DAO is held across multiple accounts backed by a multi-signature threshold scheme to minimise custody risk. If signatories across a certain threshold lose their key shares, get hacked or go rogue, we risk funds becoming locked.</p>
														</li>
														<li>
															<strong className="d-block">Slashing risk</strong>
															<p>ETH 2.0 validators risk staking penalties, with up to 100% of staked funds at risk if validators fail. To minimise this risk, mFile stakes across multiple professional and reputable node operators with heterogeneous setups, with additional mitigation in the form of insurance that is paid from mFile fees.</p>
														</li>
														<li>
															<strong className="d-block">stETH price risk</strong>
															<p>Users risk an exchange price of stETH which is lower than inherent value due to withdrawal restrictions on mFile, making arbitrage and risk-free market-making impossible. The mFile DAO is driven to mitigate above risks and eliminate them entirely to the extent possible. Despite this, they may still exist and, as such, it is our duty to communicate them.</p>
														</li>
													</ul>















												</Card.Body>
											</div>
										</Accordion.Collapse>
									</Card>

									<Card className="mb-3">
										<Card.Header className="p-0">
											<Accordion.Toggle as={Button} variant="link" eventKey="8" className="w-100 d-block text-left">
												What fee is applied by mFile? What is this used for?
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="8">
											<div>
												<div className="spacer">&nbsp;</div>
												<Card.Body>
													<p>mFile applies a 10% fee on a user’s staking rewards. This fee is split between node operators, the DAO, and an insurance fund.</p>
												</Card.Body>
											</div>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</Col>
						</Row>
					</Container>
				</section>
			<Footer />
		</React.Fragment>
	);
}

export default FAQ;